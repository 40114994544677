<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat); 

export default {
    components: {
        Layout,
        PageHeader,
    },
    page: {
        title: "URL details",
        meta: [
            {
                name: "details",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "URL details",
            items: [
                { text: "URL" },
                { text: "Details", active: true },
            ],
            csrf_token: localStorage.getItem('csrf_token'),
            urlData: [],
            hasData: true, 
            statusSummary: {},
            okStatusPercent: 0,
            selectedStatuses: [], 
            availableStatuses: [400,500], 
            timeRange: "6h",
            timeRanges: [
            { label: 'Last 6h', value: '6h' },
            { label: 'Last 12h', value: '12h' },
            { label: 'Last 1 day', value: '1d' },
            { label: 'Last 7 days', value: '7d' }
        ],
            radialChartOptions: {
                chart: {
                    type: "radialBar",
                    toolbar: { show: false },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        hollow: {
                            margin: 15,
                            size: "70%",
                        },
                        dataLabels: {
                            name: { show: false },
                            value: {
                                fontSize: "30px",
                                color: "#5b73e8",
                                offsetY: 10,
                                formatter: (val) => `${val}%`,
                            },
                        },
                    },
                },
                colors: ["#5b73e8"],
            },
            radialSeries: [],
            lineChartOptions: {
                chart: {
                    type: "line",
                    toolbar: { show: false },
                },
                xaxis: {
                    type: "category",
                    title: { text: "Time (Hours)" },
                },
                yaxis: {
                    title: { text: "Status Codes" },
                    max: 500,
                    min: 200,
                    tickAmount: 3,
                    reversed: true,
                    labels: {
                        formatter: (value) => {
                            const labelsMap = {
                                200: "200",
                                400: "400",
                                500: "500",
                            };
                            return labelsMap[value] || "";
                        },
                    },
                },
                markers: {
                    size: 6,
                },
                stroke: {
                    curve: 'stepline'
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const fullTime = w.config.series[seriesIndex].data[dataPointIndex].fullTime;
                        const statusCode = w.config.series[seriesIndex].data[dataPointIndex].y;
                        return `<div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
            <p><strong>Time:</strong> ${fullTime}</p>
            <p><strong>Status Code:</strong> ${statusCode}</p>
        </div>`;
                    },
                },
                colors: ['#5b73e8', '#ffcc00', '#ff4d4d'], 
            },

            lineChartSeries: [],
            isRadialLoading: false,
            isLineChartLoading: false, 
        };
    },
    created() {
        this.getDataForTimeRange();
    },
    computed: {
        filteredUrlData() {
        const filteredData = this.urlData.filter((row) => {
            if (row.status_code === "200") return false; 
            if (this.selectedStatuses.length === 0) return true; 
            return this.selectedStatuses.includes(row.status_code);
        });
       return filteredData;
    },
    
    },
    methods: {

async getDataForTimeRange() {
        this.isLineChartLoading = true; 
        this.isRadialLoading = true; 
        this.hasData = true;
        this.radialSeries = [];  
        try {
            const response = await Monitor.getUrlMonitoredDetails(
                this.$route.params.url_id,
                this.timeRange
            );
            const fetchedData = response.data;
            this.urlData = fetchedData.data;
            this.urlData = fetchedData.data.filter(row => row.status_code !== 200);
        
      if (this.urlData.length === 0) {
            this.hasData = false;  
        } else {
            this.okStatusPercent = fetchedData.ok_status;
            this.radialSeries = [this.okStatusPercent || 0];  
        }

            this.statusSummary = fetchedData.status_summary;
            this.okStatusPercent = fetchedData.ok_status;
            this.availableStatuses = ["400", "500"];
            this.radialSeries = [this.okStatusPercent || 0]; 

            this.populateLineChart(fetchedData.time_range_data);
        } catch (error) {
            console.error("Error fetching data for time range:", error);
            this.hasData = false; 
        } finally {
            this.isLineChartLoading = false; 
            this.isRadialLoading = false; 
        }
    },     
    populateLineChart(data) {
        const seriesData = data.map((item) => {
            const parsedTime = dayjs(item.time, "DD.MM.YYYY HH:mm");
            if (!parsedTime.isValid()) {
                return null;
            }

            return {
                x: parsedTime.format("HH:mm"), 
                y: parseInt(item.status_code), 
                fullTime: parsedTime.format("DD.MM.YYYY HH:mm"), 
            };
        }).filter(Boolean); 

        this.lineChartSeries = [
            {
                name: "HTTP Status Codes",
                data: seriesData,
            },
        ];
    },
   
        updateLineChart(range) {
        this.timeRange = range; 
        this.selectedStatuses = []; 
        this.getDataForTimeRange(); 
    },
    toggleStatus(status) {
            const index = this.selectedStatuses.indexOf(status);
            if (index > -1) {
                this.selectedStatuses.splice(index, 1);
            } else {
                this.selectedStatuses.push(status);
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="mb-4 text-center">
    <button v-for="timeRangeOption in timeRanges" 
                :key="timeRangeOption.value"
                class="btn me-2"
                :class="{ 'btn-primary': timeRangeOption.value === timeRange, 'btn-outline-primary': timeRangeOption.value !== timeRange }" 
                @click="updateLineChart(timeRangeOption.value)">
                {{ timeRangeOption.label }}
            </button>
        </div> 
        <div class="row d-flex">
            <div class="col-md-4 col-sm-12 mb-4">
                <div class="card d-flex h-100">
                    <div class="card-body text-center flex-grow-1">
                        <div v-if="isRadialLoading">
                            <p>Loading availability...</p>
                        </div>
                        <div v-if="!hasData">
        <p>No data available for the selected time range.</p>
    </div>
    <div v-else>
                    <apexchart type="radialBar" height="400" :options="radialChartOptions"
                               :series="radialSeries"></apexchart>
                            <h4>Availability</h4>
                        </div>
                    </div>
                </div>
            </div>
        <div class="col-md-8 col-sm-12 mb-4">
                <div class="card d-flex h-100">
                    <div class="card-body d-flex flex-column">
 <div v-if="isLineChartLoading" class="text-center my-4">
    <p>Loading line chart...</p>
</div>
<div v-else>
    <div v-if="!hasData">
        <p>No data available for the selected time range.</p>
    </div>
<div v-else>
    <apexchart type="line" height="300" :options="lineChartOptions" :series="lineChartSeries">
    </apexchart>
</div>
</div>
     </div>
         </div>
            </div>
        </div>
<!-- Filter and Data Table Section -->
         <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                         <!-- Status-Code Filter -->
                         <div class="mb-3">
                            <h4>Show URL Details</h4>
                            <label class="form-label mb-3">Filter by Status Code:</label>
                            <div>
        <label v-for="status in availableStatuses"
            :key="status"
            class="form-check form-check-inline"
        >
            <input type="checkbox"
                class="form-check-input"
                :value="status.toString()" 
                v-model="selectedStatuses"/>
            {{ status }}
        </label>
    </div>
        </div>
 <!-- Filtered Data Table -->
    <div v-if="isLineChartLoading || isRadialLoading" class="text-center my-4">
                <p>Loading data...</p>
                     </div>
                        <div v-if="!hasData">
        <p>No data available for the selected time range.</p>
    </div>
                        <div v-else>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>URL</th>
                                        <th>HTTP Status</th>
                                        <th>Alert Sent</th>
                                        <th>Timestamp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in filteredUrlData" :key="index">
                                        <td>{{ row.url }}</td>
                                        <td>{{ row.http_status }}</td>
                                        <td>
                                            <span v-if="row.alert_sent === 1">
                                                <i class="fa fa-check"></i>
                                            </span>
                                            <span v-else>
                                                <i class="fa fa-times"></i>
                                            </span>
                                        </td>
                                        <td>{{ row.timestamp }}</td>
                                    </tr>
                                    <tr v-if="filteredUrlData.length === 0">
                                        <td colspan="4" class="text-center">
                                            No data available.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

